import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getPurchaseByType = (req: { params: { type: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/purchases/getByType/${req.params.type}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvestments = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/invests/list?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateInvestments = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/invests/update`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const InvestService = {
  getPurchaseByType,
  getInvestments,
  updateInvestments,
};

export default InvestService;
