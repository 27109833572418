/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import UserService from "services/users";

export const LEVELS = [
  "BEGINNER",
  "SILVER",
  "GOLD",
  "RUBY",
  "DIAMOND",
  "CROWN",
  "FOUNDER_RUBY",
  "FOUNDER_DIAMOND",
  "FOUNDER_CROWN",
];

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoadingSaveDetail } = props;

  const emptyData = {
    _id: null,
    user: null,
    user_id: null,
    level: null,
    count: {},
    direct_commission: 0,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: (action) => {
      submit(action);
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async (action) => {
    try {
      setLoadingSaveDetail(true);
      if (data) {
        await UserService.updateCount({
          params: {
            id: details._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoadingSaveDetail(false);
      showToast(toast, "success", "Save success!");
      onCancel();
      reload();
    } catch (error) {
      setLoadingSaveDetail(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12  md:col-6">
        <label htmlFor="description">Level</label>
        <Dropdown
          value={details.level}
          options={LEVELS.map((l) => ({
            name: l,
            value: l,
          }))}
          onChange={(e) => onDropdownChange(e, "level")}
          optionLabel="name"
          placeholder="Select level"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">User Id</label>
        <InputText id="email" value={details.user?.refer_code} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText id="email" value={details.user?.email} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Direct commission </label>
        <InputText
          onChange={(e) => onInputChange(e, "direct_commission")}
          value={details.direct_commission}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
