/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import UserService from "services/users";
import { formatNumber } from "utils/common";
import TreeMenu from "react-simple-tree-menu";
import "react-simple-tree-menu/dist/main.css";
import { InputText } from "primereact";
import debounce from "lodash.debounce";

const View = () => {
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState(null);
  const [search, setSearch] = useState(null);
  const [openPath, setOpenPath] = useState([]);
  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    getUsers();
  }, [search]);

  useEffect(() => {
    getMembers();
    // if (users && users.length > 0) {
    //   getMembers();
    // }
  }, [users]);

  const getItems = (mapList, parentId) => {
    const items = mapList.filter((e) => e.referred_by === parentId);
    items.forEach((el) => {
      const items = getItems(mapList, el.refer_code);
      if (items && items != null && items.length > 0) {
        el.nodes = items;
      }
    });
    return items;
  };

  const getAllPaths = (node, parentPath = '') => {
    const currentPath = parentPath ? `${parentPath}/${node.key}` : node.key;
    const paths = [currentPath];
  
    if (node.nodes) {
      for (const childNode of node.nodes) {
        const childPaths = getAllPaths(childNode, currentPath);
        paths.push(...childPaths);
      }
    }
  
    return paths;
  };

  const getUsers = async () => {
    const params = search ? `email=${search}`:''
    const res: any = await UserService.getAll(params);
    if (res && res.users) {
      setUsers(res.users);
    }
  };

  const getRootUser = (users, userSearch, max_level_above=0) => {
    try {
      let result = userSearch;
      for (let index = 0; index < max_level_above; index++) {
        const _user = users.find((u) => u.refer_code === result.referred_by);
        if (_user) {
          result = _user;
          continue;
        } else return result;
      }
      return result;
    } catch (error) {
      return null;
    }
  };

  const findPathToElement = (node, targetKey, path = []) => {
    if (node.key === targetKey) {
      return [...path, targetKey];
    }
  
    if (node.nodes) {
      for (const childNode of node.nodes) {
        const result = findPathToElement(childNode, targetKey, [...path, node.key]);
        if (result) {
          return result;
        }
      }
    }
  
    return null;
  };

  const getMembers = async () => {
    if (users) {
      const _members = users.map((m) => ({
        ...m,
        key: m.refer_code,
        isOpen: true,
        focused: true,
        label: (
          <span className={`text-dark-gray dark:text-white ${m.is_cancel_invest ? 'text-cancel-invest':''}`}>
            {m.full_name} ({m.email}) 🚀{m.count.level || "MEMBER"} {" - "}
            💸 <span className={`${(m.count.count.invest || 0) < 500 ? "text-orange-600": ""}`}>${formatNumber(m.count.count.invest || 0) }</span>  {" - "}
            🫂 ${formatNumber(m.count.count.revenue_group || 0)}
          </span>
        ),
      }));
      const _userSearch = users.find((u) => u.email === search);
      let _user = null;
      if (_userSearch) {
        _user = getRootUser(users, _userSearch, 10);
      }
      const tree = _members.filter((u) =>
        _user ? u.email === _user.email : !u.referred_by
      );
      tree.forEach((el) => {
        const items = getItems(_members, el.refer_code);
        if (items && items != null && items.length > 0) {
          el.nodes = items;
        }
      });
      if(tree.length === 1){
        const allPath = getAllPaths(tree[0])
        setOpenPath(allPath)
        // Get path to active element
        const activePath = findPathToElement(tree[0], _userSearch.refer_code)
        setActivePath(activePath.join("/"))
      }
      else{
        setOpenPath([])
        setActivePath("")
      }
      setMembers(tree);
    }
  };

  const onFilter = (value) => {
    setSearch(value.trim());
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  return (
    <div className="card">
      {" "}
      <div>
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch(e.target.value)}
            placeholder="Search..."
          />
        </span>
      </div>
      {members && (
        <TreeMenu
          initialOpenNodes={openPath}
          resetOpenNodesOnDataUpdate
          data={members}
          hasSearch={false}
          activeKey={activePath}
          focusKey={activePath}
          initialActiveKey={activePath}
        />
      )}{" "}
    </div>
  );
};

export default View;
