/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { formatNumber, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import InvestService from "services/invests";

const STATUS = [
  { name: "Success", value: "success", disabled: true },
  { name: "Waiting user confirm", value: "waiting_user_confirm" },
  { name: "Waiting admin confirm", value: "waiting_admin_confirm" },
  { name: "Waiting payback", value: "waiting_payback" },
  { name: "Reinvested", value: "reinvested", disabled: true },
  { name: "Completed", value: "completed", disabled: true },
];

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoadingSaveDetail } =
    props;

  const emptyData = {
    id: null,
    user: null,
    investment: null,
    status: null,
    createdAt: null
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: (action) => {
      submit(action);
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async (action) => {
    try {
      setLoadingSaveDetail(true);
      if (data) {
        await InvestService.updateInvestments({
          body: {
            id: data._id,
            status: details.status
          },
        });
      }
      setLoadingSaveDetail(false);
      showToast(toast, "success", "Save success!");
      onCancel();
      reload();
    } catch (error) {
      setLoadingSaveDetail(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID</label>
        <InputText id="email" value={details.id} disabled={true} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText id="email" value={details.user?.email} disabled={true} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Amount </label>
        <InputText value={`${formatNumber(details.investment?.amount)}`} disabled={true} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Period </label>
        <InputText value={details.investment?.time_lock} disabled={true} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Remain days </label>
        <InputText value={(details.investment?.time_lock||0) - (details.investment?.number_unlock ||0)} disabled={true} />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Daily profit </label>
        <InputText value={details.investment?.daily_profit} disabled={true} />
      </div>
      <div className="field col-12  md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          disabled={data?.status !== "waiting_admin_confirm"}
          value={details.status}
          options={STATUS}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="name"
          placeholder="Select status"
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
