import React, { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import AuthService from "services/auth";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";

const UpdatePassword = () => {
  const toast = useRef(null);
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [loading, setLoading] = useState(false);

  const { auth } = useContext(MasterContext);

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (!passwordData.new_password.length || !passwordData.current_password.length || !passwordData.confirm_password.length){
        showToast(toast, "warn", "Please enter value!");
        setLoading(false);
        return;
      }

      if (passwordData.new_password !== passwordData.confirm_password ){
        showToast(toast, "warn", "Confirm password must be same as new password!");
        setLoading(false);
        return;
      }

      await AuthService.updatePassword({
        body: {
          ...passwordData
        },
      });
      showToast(toast, "success", "Update password success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const msg = (error.errors === "OTP_HAS_EXPIRED" || error.errors === "OTP_INCORRECT") ? "OTP invalid or expired": error.errors;
      showToast(toast, "error", msg);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12 xl:col-6">
        <div className="card">
          <div className="row">
            <div className="col-12">
              <label
                htmlFor="old_password"
                className="block text-900 text-xl font-medium mb-2"
              >
                Current Password
              </label>
              <InputText
                id="old_password"
                name="old_password"
                type="password"
                required
                value={passwordData.current_password}
                className="w-full"
                onChange={(e) => setPasswordData({...passwordData, current_password: e.target.value})}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label
                htmlFor="old_password"
                className="block text-900 text-xl font-medium mb-2"
              >
                New Password
              </label>
              <InputText
                id="new_password"
                name="new_password"
                type="password"
                required
                value={passwordData.new_password}
                className="w-full"
                onChange={(e) => setPasswordData({...passwordData, new_password: e.target.value})}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label
                htmlFor="old_password"
                className="block text-900 text-xl font-medium mb-2"
              >
                Confirm New Password
              </label>
              <InputText
                id="confirm_password"
                name="confirm_password"
                type="password"
                required
                value={passwordData.confirm_password}
                className="w-full"
                onChange={(e) => setPasswordData({...passwordData, confirm_password: e.target.value})}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mt-2 btn-end">
                <Button label="Submit" loading={loading} onClick={submitHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default UpdatePassword;
