/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar} from "primereact";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import KycService from "services/kycs";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import { formatDate } from "@fullcalendar/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    status: "pending",
    note: "",
    id: "",
    birthday: null,
    value_kyc: {
      front: null,
      back: null,
      selfie: null,
    },
    email: null,
    first_name: null,
    last_name: null,
    // phone: null,
    // country_code: null
  };

  const optionStatus = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Declined",
      value: "declined",
    },
  ];

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...data,
        birthday: new Date(data.user.birthday),
        email: data.user.email,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        // phone: data.user.phone?.slice(data.user.country_code?.length + 1 || 0),
        // country_code: data.user.country_code
      });
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await KycService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeBirthday = (e) => {
    setDetails({
      ...details,
      birthday: formatDate(e.target.value)
    })
  }

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText
          id="email"
          type="text"
          disabled
          value={details.email || ""}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={optionStatus}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">First Name</label>
        <InputText
          id="first_name"
          type="text"
          value={details.first_name || ""}
          onChange={(e) => onInputChange(e, "first_name")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Last Name</label>
        <InputText
          id="last_name"
          type="text"
          value={details.last_name || ""}
          onChange={(e) => onInputChange(e, "last_name")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Number</label>
        <InputText
          id="idKyc"
          value={details.id}
          onChange={(e) => onInputChange(e, "id")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Birthday</label>
        <Calendar value={details.birthday} onChange={onChangeBirthday} showIcon />
      </div>

      {/* <div className="field col-12 md:col-6">
        <label htmlFor="description">Phone Number</label>
        <PhoneInput
          inputClass="w-full"
          country={"jp"}
          countryCodeEditable={false}
          enableSearch
          disableSearchIcon
          value={`+${details.country_code}${details.phone}`}
          onChange={(value, data) => {
            const rawPhone = value.slice(data['dialCode'].length);
            setDetails({
              ...details,
              phone: rawPhone,
              country_code: data['dialCode'],
            });
          }}
        />
      </div> */}

      <div className="field col-12">
        <label htmlFor="description">Note</label>
        <InputTextarea
          id="note"
          value={details.note}
          onChange={(e) => onInputChange(e, "note")}
          autoResize
          rows={3}
        />
      </div>
      {details.value_kyc.front && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Front:</label>
          <br></br>
          <Image
            src={details.value_kyc.front}
            width="150"
            height="150"
            preview
          />
        </div>
      )}
      {details.value_kyc.back && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Back:</label>
          <br></br>
          <Image
            src={details.value_kyc.back}
            width="150"
            height="150"
            preview
          />
        </div>
      )}
      {details.value_kyc.selfie && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Selfie:</label>
          <br></br>
          <Image
            src={details.value_kyc.selfie}
            width="150"
            height="150"
            preview
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
