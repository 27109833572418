/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    first_name: "",
    last_name: "",
    full_name: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    referred_by: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.avatar && (
          <img
            src={details.avatar}
            alt={details.avatar}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onInputChange(e, "email")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Phone</label>
        <InputText
          id="phone"
          value={details.phone}
          onChange={(e) => onInputChange(e, "phone")}
          required
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="name">First Name</label>
        <InputText
          id="first_name"
          value={details.first_name}
          onChange={(e) => onInputChange(e, "first_name")}
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="name">Last Name</label>
        <InputText
          id="last_name"
          value={details.last_name}
          onChange={(e) => onInputChange(e, "last_name")}
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="name">Full Name</label>
        <InputText id="fullName" value={details.full_name} disabled />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Role</label>
        <Dropdown
          defaultValue={details.role}
          value={details.role}
          options={[
            {
              label: "USER",
              value: "USER",
            },
            {
              label: "ADMIN",
              value: "ADMIN",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "role")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            {
              label: "Confirmed",
              value: "confirmed",
            },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Blocked",
              value: "blocked",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Sponsor</label>
        <InputText
          id="referred_by"
          value={details.referred_by}
          onChange={(e) => onInputChange(e, "referred_by")}
          required
          disabled
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
