import { request, parseErrorResponse } from "./request";

const login = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("/admin/login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (data: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/register", data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/resend-otp", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updatePassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put("/admin/account/update-password", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
  register,
  resendCode,
  updatePassword,
};

export default AuthService;
