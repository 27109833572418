import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { forwardRef, useImperativeHandle, useState } from "react";

const View = (props, ref) => {
  const { onConfirm, onShow, header } = props;

  const [showDetail, setShowDetail] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setShowDetail(true);
    },

    close: () => {
      setShowDetail(false);
    },
  }));

  const cancel = () => {
    setShowDetail(false);
  };

  const confirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog
      header={header}
      visible={showDetail}
      modal
      contentClassName="over"
      className="p-fluid"
      style={{ width: "450px" }}
      footer={
        <>
          <Button
            label="No"
            icon="pi pi-times"
            className="p-button-text"
            onClick={cancel}
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            className="p-button-text"
            onClick={confirm}
          />
        </>
      }
      onHide={cancel}
      onShow={onShow}
    >
      <div className="p-2">
        {" "}
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Are you sure you want to delete?</span>
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef(View);
