import React, { useEffect, useRef, useState } from "react";
import { InputSwitch, Toast } from "primereact";
import SettingService from "services/settings";
import { formatLocalTime, showToast } from "utils/common";
const SystemSettings = () => {
  const toast = useRef(null);
  const [isAllowWithdraw, setIsAllowWithdraw] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "is_allow_withdraw",
        },
      });
      if (res) {
        const { setting } = res;
        setSettings(setting);
        setIsAllowWithdraw(setting.value);
      }
    } catch (error) {}
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      setIsAllowWithdraw(!isAllowWithdraw);
      const resp: any = await SettingService.updateSetting({
        body: {
          name: "is_allow_withdraw",
          value: !isAllowWithdraw,
        },
      });
      await getData();
      showToast(toast, "success", "Update success!");
      setLoading(false);
      if (resp) {
        const { setting } = resp;
        setSettings(setting);
      }
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  }

  return (
    <div className="grid">
      <Toast ref={toast} />

        <div className="card w-full">
          <div className="row">
            <div className="col-12">
              <h5>Allow withdraw (Most recent update: {formatLocalTime(settings?.updatedAt)})</h5>
              <p className="mb-4 mt-1">Allow users to withdraw money from their account.</p>
              <InputSwitch
                checked={isAllowWithdraw}
                onChange={(e) => submitHandler()}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

export default SystemSettings;
