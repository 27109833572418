import React, { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import AuthService from "services/auth";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";

const StockPrices = () => {
  const toast = useRef(null);
  const [state, setState] = useState(
    "0xB2087AFaB198659c1F91a3736fC10190284514D9"
  );
  const [confirmCode, setConfirmCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const { auth } = useContext(MasterContext);

  useEffect(() => {
    getData();
  }, []);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (!confirmCode.length || !state.length){
        showToast(toast, "warn", "Please enter value!");
        setLoading(false);
        return;
      }
      await SettingService.updateSetting({
        body: {
          name: "wallet_admin",
          value: state,
          confirmCode: confirmCode,
        },
      });
      await getData();
      showToast(toast, "success", "Update success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const msg = (error.errors === "OTP_HAS_EXPIRED" || error.errors === "OTP_INCORRECT") ? "OTP invalid or expired": error.errors;
      showToast(toast, "error", msg);
    }
  };

  const handleSendCode = () => {
    setIsSend(true);
    AuthService.resendCode({
      body: { email: auth.email },
    });
    showToast(toast, "success", "OTP sent to your email.");
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "wallet_admin",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"]
            ? setting["value"]
            : "0xB2087AFaB198659c1F91a3736fC10190284514D9";
          setState(value);
        }
      }
    } catch (error) {}
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      {/* <div className="col-12"> */}
        <div className="card w-full">
          <div className="row">
            <div className="col-12">
              <InputText
                required
                value={state}
                className="w-full"
                onChange={(e) => setState((state) => e.target.value)}
              />
            </div>
          </div>
                  
          <div className="row flex">
            <div className="col-8">
              <InputText
                required
                type="number"
                placeholder="Enter confirm code"
                className="w-full"
                value={confirmCode}
                onChange={(e) => setConfirmCode((state) => e.target.value)}
              />
            </div>
            <div className="col-4">
              <Button
                label={`${!isSend ? "Send Code" : "Resend"}`}
                className="p-button-outline"
                // loading={loading}
                onClick={handleSendCode}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mt-2">
                <Button
                  label="Cancel"
                  className="p-button-danger mr-2"
                  loading={loading}
                  onClick={handleCancel}
                />
                <Button label="Submit" loading={loading} onClick={submitHandler} />
              </div>
            </div>
          </div>

        </div>
      {/* </div> */}
    </div>
  );
};

export default StockPrices;
