/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber } from "utils/common";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact";
import VDialog from "components/v-dialog";
import Details from "./components/Details";
import InvestService from "services/invests";
import { INVEST_STYLE_STATUS } from "utils/constant";
import { renderAmount } from "utils/render";

const STATUS = [
  // { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Waiting user confirm", value: "waiting_user_confirm" },
  { name: "Waiting admin confirm", value: "waiting_admin_confirm" },
  { name: "Waiting payback", value: "waiting_payback" },
  { name: "Reinvested", value: "reinvested" },
  { name: "Completed", value: "completed" },
];

const View = () => {
  const [, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: "waiting_admin_confirm",
    type: null,
    email: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [detail, setDetail] = useState(null);
  const [userCount, setUserCount] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);

  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await InvestService.getInvestments({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
        setUserCount(res.userCount);
      } else {
        setData([]);
        setUserCount(null);
      };
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="col-2">
        <h5 className="m-0">Investments</h5>
      </div>

      <div className="col-10">
        <div className="grid my-2 align-items-center">

          <span className="block m-1 p-input-icon-left">
            <i className="pi pi-user" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("email", e.target.value.trim())}
              placeholder="Email"
            />
          </span>{" "}

          <Dropdown
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            className={"w-18rem m-1"}
            showClear
          />
          {userCount && (<>
            <div className="p-component p-inputtext m-1">
              <span className="">
                Total Invest: {renderAmount(userCount?.invest || 0)}
              </span>
            </div>

            <div className="p-component p-inputtext m-1">
              <span className="">
                Total Payback:{" "}
                {renderAmount(userCount?.total_payback || 0)}
              </span>
            </div></>)
          }
        </div>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon={rowData.status === "waiting_admin_confirm" ? "pi pi-pencil" : "pi pi-eye"}
          className={`p-button-rounded p-button-sm mr-2 ${rowData.status === "waiting_admin_confirm" ? "p-button-success" : ""}`}
          onClick={() => editProduct(rowData)}
        />
      </div>
    );
  };

  console.log({userCount})

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="id"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <span>
                  {rowData._id}
                </span>
              )}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => <span>{rowData.user?.email || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span className={`v-badge invest-status-${rowData.status.toLowerCase()}`}>
                  {INVEST_STYLE_STATUS[rowData.status][0]}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amount"
              header="Amount"
              body={(rowData) => formatNumber(Math.abs(rowData.investment.amount))}
            >
            </Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="period"
              header="Period"
              body={(rowData) => (
                <span>
                  {rowData.investment.time_lock} days
                </span>
              )}
            >
            </Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="investment.remain_day"
              header="Remain days"
              sortable
              body={(rowData) => (
                <span>
                  {(rowData?.investment?.time_lock || 0) - (rowData?.investment?.number_unlock || 0)} days
                </span>
              )}
            >
            </Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="daily_profit"
              header="Daily profit"
              body={(rowData) => formatNumber(Math.abs(rowData.investment.daily_profit))}
            >
            </Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              frozen
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
            isDisableFooter={detail?.status !== "waiting_admin_confirm"}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoadingSaveDetail={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
